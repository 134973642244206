import React from 'react'
import Card from '../../Card'
import BigData from '../BigData'

import styles from '../YearInReview.module.css'
import PercentChange from './PercentChange'

const formatter = new Intl.NumberFormat('en-US')

export default function CountUp({
  topSubhead,
  heading,
  number,
  enableCounting,
  percentChange,
}) {
  return (
    <Card>
      {topSubhead && <h3 className={styles.subheading}>{topSubhead}</h3>}
      <h2
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: heading }}
        className={styles.bigDataHeading}
      />

      <div style={{ display: 'flex' }}>
        {enableCounting && <BigData number={number} />}
        {!enableCounting && (
          <p className={styles.bigDataText}>{formatter.format(number)}</p>
        )}
        {percentChange && <PercentChange percentChange={percentChange} />}
      </div>
    </Card>
  )
}

import React from 'react'

import Card from '../Card'

import styles from './YearInReview.module.css'

export default function NameList({ subheading, heading, contacts }) {
  return (
    <Card>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      <h2
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: heading }}
        className={styles.heading}
      />

      <ul className={styles.nameList}>
        {contacts.map(contact => {
          const { name, url } = contact
          const nameParts = name.split(' ')

          const firstInitial = nameParts[0].charAt(0)
          const secondInitial = nameParts.slice(-1)[0].charAt(0)

          return (
            <li className={styles.name}>
              <a
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.innerName}
              >
                <span className={styles.initials}>
                  {firstInitial}
                  {secondInitial}
                </span>

                {name}
              </a>
            </li>
          )
        })}
      </ul>
    </Card>
  )
}

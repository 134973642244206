import React, { useState } from 'react'
import groupBy from 'lodash/groupBy'

import Header from './Header'
import Month from './Month'
import Module from './Module'
import ContentPanel from './ContentPanel'
import TimeColumn from './TimeColumn'
import styles from './YearInReview.module.css'
import Timezones from './Timezones'
import NameList from './NameList'
import CountUp from './CountUp'
import ImageGrid from './ImageGrid'
import Newsletter from './Newsletter'
import Cities from './Cities'
import TypeSpecimen from './TypeSpecimen'
import Playlist from './Playlist'
import MonthContextProvider from './MonthContextProvider'

export default function YearInReview({ content }) {
  const groupedContent = groupBy(content, 'layout.section')

  return (
    <MonthContextProvider>
      <Header months={Object.keys(groupedContent)} />

      <main className={styles.main}>
        <TimeColumn />
        <div className={styles.contentColumn}>
          {Object.entries(groupedContent).map(([month, section]) => (
            <Month key={month} month={month} noLine={month === 'intro'}>
              <div className={styles.contentConstraint}>
                {section.map(module => (
                  <ContentPanel
                    align={module.layout.align}
                    width={module.layout.size}
                    offset={module.layout.top_offset}
                    offsetMobile={module.layout.top_offset_mobile}
                    lineBreakAfter={module.layout.line_break_after}
                  >
                    {(() => {
                      if (module.timezones) {
                        return (
                          <Timezones
                            subheading={module.top_subhead}
                            heading={module.heading}
                            timezones={module.timezones}
                          />
                        )
                      }

                      if (module.contacts) {
                        return (
                          <NameList
                            subheading={module.top_subhead}
                            heading={module.heading}
                            contacts={module.contacts}
                          />
                        )
                      }

                      if (module.number) {
                        return (
                          <CountUp
                            heading={module.heading}
                            topSubhead={module.top_subhead}
                            percentChange={module.percent_change}
                            number={module.number}
                            enableCounting={module.enable_counting}
                          />
                        )
                      }

                      if (module.images) {
                        return (
                          <ImageGrid
                            heading={module.heading}
                            subheading={module.top_subhead}
                            images={module.images}
                          />
                        )
                      }

                      if (module.newsletter) {
                        return (
                          <Newsletter
                            subheading={module.top_subhead}
                            heading={module.heading}
                          />
                        )
                      }

                      if (module.street_views) {
                        return (
                          <Cities
                            subheading={module.top_subhead}
                            heading={module.heading}
                            cities={module.street_views}
                          />
                        )
                      }

                      if (module.specimen_text) {
                        return (
                          <TypeSpecimen
                            subheading={module.top_subhead}
                            heading={module.heading}
                            defaultText={module.specimen_text}
                          />
                        )
                      }

                      if (module.playlist_url) {
                        return (
                          <Playlist
                            subheading={module.top_subhead}
                            heading={module.heading}
                            url={module.playlist_url}
                          />
                        )
                      }

                      return (
                        <Module
                          backgroundColor={module.backgroundColor}
                          subheading={module.top_subhead}
                          heading={module.heading}
                          body={module.bodyHTML}
                          image={module.image}
                          imageUrl={module.image_url}
                          slides={module.slides}
                          linkUrl={module.link_url}
                        />
                      )
                    })()}
                  </ContentPanel>
                ))}
              </div>
            </Month>
          ))}
        </div>
      </main>
    </MonthContextProvider>
  )
}

import React, { useRef, useState } from 'react'
import ReactSwipe from 'react-swipe'
import Clock from '../Clock'
import Card from '../Card'

import styles from './YearInReview.module.css'

export default function Timezones({ subheading, heading, timezones }) {
  const swipeRef = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <Card>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      {heading && (
        <h2
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: heading }}
          style={{ fontWeight: 600 }}
        />
      )}

      <div className={styles.clockContainer}>
        <ReactSwipe
          ref={swipeRef}
          childCount={timezones.length}
          swipeOptions={{
            startSlide: currentSlide,
            transitionEnd: index => setCurrentSlide(index),
          }}
        >
          {timezones.map(timezone => (
            <div className={styles.slideContainer}>
              <h3 className={styles.timezoneHeading}>{timezone.name}</h3>

              <Clock utcOffset={timezone.utc_offset} />
            </div>
          ))}
        </ReactSwipe>

        <button
          aria-label="Previous photo"
          className={styles.timezonePrevious}
          onClick={() => swipeRef.current.prev()}
          type="button"
        />
        <button
          aria-label="Next photo"
          className={styles.timezoneNext}
          onClick={() => swipeRef.current.next()}
          type="button"
        />

        <div className={styles.slideIndicators}>
          {timezones.map((_, i) => (
            <button
              aria-label={`Go to slide ${i}`}
              type="button"
              onClick={() => swipeRef.current.slide(i)}
              className={[
                styles.slideIndicator,
                ...(i === currentSlide ? [styles.activeIndicator] : []),
              ].join(' ')}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

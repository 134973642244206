import React, { useEffect, useState } from 'react'

export default function Clock({ utcOffset }) {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000)

    return () => {
      clearInterval(timer)
    }
  })

  const hours = currentTime.getUTCHours() + utcOffset
  const minutes = currentTime.getMinutes()
  const seconds = currentTime.getSeconds()

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 366 366">
      <g id="background">
        <circle fill="#fff" cx="183" cy="183" r="183" />
      </g>

      <g id="numbers" fill="#1e1e1e">
        <path d="M169.12,24h-.37L161,29.46V24.24l8.1-5.69h5.52v31h-5.54Z" />
        <path d="M183.28,45.81l10.18-10.4c4.21-4.25,5.39-5.82,5.39-8.2v-.07c0-2.85-1.95-4.92-5.18-4.92a5.16,5.16,0,0,0-5.5,5.37l0,.09-5.15,0,0-.07c0-5.78,4.52-9.81,11-9.81,6,0,10.38,3.67,10.38,8.89v.06c0,3.53-1.7,6.32-7.35,11.76l-6.21,6V45h14v4.6H183.28Z" />
        <path d="M97,43.64H96.6l-7.71,5.45V43.87L97,38.18h5.52v31H97Z" />
        <path d="M118.71,43.64h-.37l-7.71,5.45V43.87l8.1-5.69h5.52v31h-5.54Z" />
        <path d="M38.33,97.73H38l-7.72,5.46V98l8.1-5.69h5.52v31H38.33Z" />
        <path d="M52.06,107.79v0c0-10,4.43-16.25,11.79-16.25s11.84,6.23,11.84,16.25v0c0,10-4.47,16.26-11.83,16.26S52.06,117.8,52.06,107.79Zm18,0v0c0-7.35-2.3-11.73-6.26-11.73s-6.2,4.38-6.2,11.73v0c0,7.35,2.25,11.77,6.21,11.77S70.11,115.14,70.11,107.79Z" />
        <path d="M46,182.6v0c0,10.44-4.71,16.63-12.51,16.63-5.69,0-10-3.38-10.93-8.12l0-.24H28l.06.21a5.5,5.5,0,0,0,5.46,3.59c4.92,0,7-4.81,7.24-10.74,0-.24,0-.47,0-.71h-.43c-1.13,2.45-3.84,4.62-8.1,4.62A9.81,9.81,0,0,1,22.1,177.65v0c0-6.29,4.8-10.89,11.52-10.89S46,171.42,46,182.6Zm-6.21-5v0a6.14,6.14,0,1,0-12.27-.13v0a5.92,5.92,0,0,0,6.08,6.17A6,6,0,0,0,39.79,177.59Z" />
        <path d="M41.8,264v0c0-4,2.9-7.05,7-7.88v-.13c-3.55-1-5.91-3.55-5.91-6.88v0c0-4.77,4.56-8.27,11-8.27s11,3.5,11,8.27v0c0,3.33-2.37,5.93-5.91,6.88V256c4.15.83,7,3.91,7,7.88v0c0,5.31-5,9-12.09,9S41.8,269.26,41.8,264Zm17.6-14.41v0c0-2.71-2.26-4.6-5.5-4.6s-5.5,1.89-5.5,4.6v0c0,2.71,2.26,4.6,5.5,4.6S59.4,252.25,59.4,249.54Zm.86,14v0c0-3-2.62-5.18-6.36-5.18s-6.36,2.17-6.36,5.18v0c0,3,2.64,5.13,6.36,5.13S60.26,266.58,60.26,263.59Z" />
        <path d="M113.48,308.17v-.34h-16V303.3h21.57v4.62L105.81,334.3H100Z" />
        <path d="M171.07,332.35v-.05c0-10.44,4.7-16.63,12.5-16.63,5.7,0,10,3.38,10.94,8.12l0,.24h-5.43l-.09-.21a5.5,5.5,0,0,0-5.46-3.59c-4.92,0-7,4.81-7.24,10.74,0,.24,0,.47,0,.71h.43c1.14-2.45,3.85-4.62,8.1-4.62A9.81,9.81,0,0,1,195,337.29v0c0,6.3-4.79,10.89-11.52,10.89S171.07,343.52,171.07,332.35Zm12.33,11.36a6.11,6.11,0,0,0,6.14-6.19v0a6.13,6.13,0,0,0-12.26-.13v0A6.15,6.15,0,0,0,183.4,343.71Z" />
        <path d="M247,321.94l0-.28h5.12l0,.15a5.72,5.72,0,0,0,5.93,4.56,5.89,5.89,0,0,0,6.12-6.21v-.05a5.87,5.87,0,0,0-6.08-6.14,6.31,6.31,0,0,0-4.38,1.55,6.07,6.07,0,0,0-1.44,1.76H247.5L249,299.12h18.6v4.56H253.32l-.86,9.43h.37a7.53,7.53,0,0,1,6.62-3.33c5.78,0,10,4.3,10,10.23v0c0,6.44-4.71,10.85-11.41,10.85C251.67,330.9,247.39,327,247,321.94Z" />
        <path d="M312,264.66H296.81v-4.54c4-7,8.42-14.11,12.63-20.51h7.84v20.47h4.17v4.58h-4.17v5.95H312Zm.09-4.45V244h-.32c-3.18,4.86-6.77,10.59-9.78,15.86v.32Z" />
        <path d="M320.35,190.15l0-.26h5.29l0,.24c.3,2.62,2.73,4.49,6.36,4.49s6-2,6-4.88v0c0-3.27-2.4-5.07-6.38-5.07h-3.65v-4.12h3.55c3.41,0,5.69-1.94,5.69-4.65v0c0-2.83-1.91-4.51-5.22-4.51s-5.52,1.74-5.8,4.51l0,.22H321l0-.24c.47-5.37,4.71-8.89,11-8.89s10.52,3.33,10.52,8.18v0c0,3.85-2.77,6.41-6.48,7.18v.11c4.55.43,7.58,3.13,7.58,7.43v0c0,5.46-4.7,9.26-11.58,9.26S320.75,195.46,320.35,190.15Z" />
        <path d="M299.29,119.85l10.19-10.4c4.21-4.25,5.39-5.82,5.39-8.2v-.07c0-2.85-2-4.92-5.18-4.92a5.15,5.15,0,0,0-5.5,5.37l0,.09-5.16,0,0-.07c0-5.78,4.51-9.81,11-9.81,6,0,10.38,3.67,10.38,8.89v.06c0,3.53-1.7,6.32-7.35,11.76l-6.21,6V119h14v4.6H299.29Z" />
        <path d="M257.06,45.33h-.36L249,50.78V45.56l8.1-5.69h5.52v31h-5.54Z" />
      </g>

      <g id="hands">
        <g transform={`rotate(${seconds * 6}, 183, 183)`}>
          <rect
            fill="#eb4d3d"
            x="98.5"
            y="95.5"
            width="169"
            height="6"
            rx="3"
            transform="translate(281.5 -84.5) rotate(90)"
          />
        </g>
        <g transform={`rotate(${hours * 30}, 183, 183)`}>
          <rect
            fill="#1e1e1e"
            x="133.7"
            y="130.7"
            width="98.61"
            height="6"
            rx="3"
            transform="translate(316.7 -49.3) rotate(90)"
          />
        </g>
        <g transform={`rotate(${minutes * 6}, 183, 183)`}>
          <rect
            fill="#1e1e1e"
            x="102.62"
            y="99.62"
            width="160.76"
            height="6"
            rx="3"
            transform="translate(285.62 -80.38) rotate(90)"
          />
        </g>
        <circle
          fill="#eb4d3d"
          stroke="#1e1e1e"
          strokeWidth="6px"
          cx="183.25"
          cy="182.75"
          r="6.75"
        />
      </g>
    </svg>
  )
}

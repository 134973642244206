import React from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import styles from './YearInReview.module.css'

export default function Playlist({ subheading, heading, url }) {
  let embedUrl

  if (!url.includes('embed')) {
    embedUrl = url.replace('/playlist', '/embed/playlist')
  } else {
    embedUrl = url
  }

  return (
    <Card>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      {heading && (
        <h2
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: heading }}
          className={styles.heading}
        />
      )}

      <div className={styles.playlistContainer}>
        <iframe
          title="Songs"
          src={embedUrl}
          height="344"
          width="100%"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
        />
      </div>
    </Card>
  )
}

Playlist.propTypes = {
  subheading: PropTypes.string.isRequired,
  heading: PropTypes.string,
  url: PropTypes.string.isRequired,
}

Playlist.defaultProps = {
  heading: '',
}

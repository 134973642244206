import React, { useState } from 'react'

export const MonthContext = React.createContext(['intro', () => {}])

export default function MonthContextProvider({ children }) {
  const activeMonthSetterGetter = useState('intro')

  return (
    <MonthContext.Provider value={activeMonthSetterGetter}>
      {children}
    </MonthContext.Provider>
  )
}

import React, { useEffect, useRef, useContext } from 'react'
// import { useInView } from 'react-intersection-observer'
import useInView from '../../hooks/useInView'
import { MonthContext } from './MonthContextProvider'

import styles from './YearInReview.module.css'

export function getMonthName(month) {
  if (month === 'intro' || month === 'stats' || month === 'next') {
    return month
  }

  return month.substr(0, 3)
}

export default function Month({ month, noLine, children }) {
  const ref = useRef()
  const [, setActiveMonth] = useContext(MonthContext)

  const inView = useInView(ref, 100)

  useEffect(() => {
    if (inView) {
      setActiveMonth(month)
    }
  }, [inView, month])

  const classes = [styles.monthSection]
  if (noLine) {
    classes.push(styles.noLine)
  }

  return (
    <section ref={ref} className={classes.join(' ')} id={month}>
      {!noLine && (
        <h2 className={styles.monthHeading}>{getMonthName(month)}</h2>
      )}
      {children}
    </section>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import styles from './YearInReview.module.css'

export default function Module({ subheading, heading, defaultText }) {
  return (
    <Card>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      {heading && (
        <h2
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: heading }}
          className={styles.heading}
        />
      )}

      <textarea className={styles.typeSpecimen} defaultValue={defaultText} />
    </Card>
  )
}

Module.propTypes = {
  subheading: PropTypes.string.isRequired,
  heading: PropTypes.string,
}

Module.defaultProps = {
  heading: '',
}

import React, { useRef, useEffect, useState } from 'react'
import { useCountUp } from 'react-countup'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'

// import useInView from '../../hooks/useInView'
import styles from './YearInReview.module.css'

export default function BigData({ number }) {
  // const ref = useRef()
  const [hasCounted, setHasCounted] = useState(false)
  const { countUp, start } = useCountUp({
    start: 0,
    end: number,
    separator: ',',
    startOnMount: false,
    decimals: number % 1 === 0 ? 0 : 2,
  })

  // const inView = useInView(ref, -400)
  const { ref, inView } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (inView && !hasCounted) {
      start()
      setHasCounted(true)
    }
  }, [inView, hasCounted])

  return (
    <p ref={ref} className={styles.bigDataText}>
      {countUp}
    </p>
  )
}

BigData.propTypes = {
  number: PropTypes.number.isRequired,
}

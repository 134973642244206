import React from 'react'
import { graphql } from 'gatsby'
import YearInReview from '../components/YearInReview'
import SEO from '../components/SEO'

const slugToTitleMap = {
  'year-in-review': 'Year in Review: 2020',
  'year-in-review-21': 'Year in Review: 2021',
  'year-in-review-22': 'Year in Review: 2022',
}

export default function YearInReviewPage({ data }) {
  if (data?.strapiYearInReview === null) {
    return null
  }

  const content = data?.strapiYearInReview?.content ?? []

  const title =
    slugToTitleMap[data?.strapiYearInReview?.slug] ?? 'Year in Review'

  return (
    <>
      <SEO title={title} />
      <YearInReview content={content} />
    </>
  )
}

export const yearInReviewFragment = graphql`
  fragment YearInReview on StrapiYearInReview {
    content {
      heading
      bodyHTML
      image_url
      image {
        extension
        base
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      images {
        caption
        localFile {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      top_subhead
      percent_change
      layout {
        top_offset
        size
        align
        section
        top_offset_mobile
        line_break_after
      }
      timezones {
        id
        name
        utc_offset
      }
      contacts {
        id
        name
        url
      }
      number
      enable_counting
      newsletter
      street_views {
        address1
        address2
        id
        initials
        latitude
        longitude
        street_view_heading
      }
      specimen_text
      playlist_url
      link_url
      slides {
        id
        vimeo_url
        vimeo_width
        vimeo_height
        image_url
        caption
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    slug
    draft
  }
`

export const query = graphql`
  query($slug: String!) {
    strapiYearInReview(slug: { eq: $slug }, draft: { ne: true }) {
      ...YearInReview
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import Image from '../Image'
import Carousel from '../Carousel'
import styles from './YearInReview.module.css'

export default function Module({
  subheading,
  heading,
  body,
  image,
  imageUrl,
  backgroundColor,
  slides,
  linkUrl,
}) {
  const cardStyle = { display: 'block' }
  const headingClasses = [styles.heading]

  if (backgroundColor) {
    cardStyle.backgroundColor = backgroundColor
  }

  let extraAttributes = {}

  if (linkUrl) {
    extraAttributes.as = 'a'
    extraAttributes.href = linkUrl
    extraAttributes = {
      ...extraAttributes,
      a: 'a',
      href: linkUrl,
      target: '_blank',
      rel: 'noreferrer noopener',
    }
  }

  return (
    <Card style={cardStyle} {...extraAttributes}>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      {heading && (
        <h2
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: heading }}
          className={headingClasses.join(' ')}
        />
      )}

      {body && (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: body }}
          className={styles.body}
        />
      )}
      {slides && slides.length > 0 && <Carousel slides={slides} />}
      {(!slides || slides.length === 0) && image && (
        <Image className={styles.image} image={image} url={imageUrl} alt="" />
      )}
    </Card>
  )
}

Module.propTypes = {
  subheading: PropTypes.string.isRequired,
  heading: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  backgroundColor: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.object),
  linkUrl: PropTypes.string,
}

Module.defaultProps = {
  heading: '',
  body: '',
  image: '',
  backgroundColor: null,
  slides: null,
  linkUrl: null,
}

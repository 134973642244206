import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import styles from './YearInReview.module.css'

export default function Module({ subheading, heading }) {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [isSending, setIsSending] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    setIsSending(true)

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    fetch('https://cms.portorocha.com/subscribe', {
      method: 'POST',
      body: JSON.stringify({
        email,
        firstName,
        lastName,
      }),
      headers,
    })
      .then(response => response.json())
      .then(data => {
        setIsSending(false)

        if (data.status === 'subscribed') {
          setIsConfirmed(true)
          setEmail('')
          setFirstName('')
          setLastName('')

          setTimeout(() => setIsConfirmed(false), 3000)
        } else {
          // eslint-disable-next-line no-console
          console.error(data)
        }
      })
  }

  return (
    <Card>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      {heading && (
        <h2
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: isConfirmed
              ? 'Your subscription to our list has been confirmed. Thank you for subscribing!'
              : heading,
          }}
          className={styles.heading}
        />
      )}

      <form
        onSubmit={handleSubmit}
        style={{ position: 'relative', marginTop: '36px' }}
      >
        <input
          className={styles.newsletterInput}
          type="text"
          placeholder="First name"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          required
          disabled={isSending}
        />
        <input
          className={styles.newsletterInput}
          type="text"
          placeholder="Last name"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          required
          disabled={isSending}
        />
        <input
          className={styles.newsletterInput}
          type="email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          disabled={isSending}
        />
        <button
          type="submit"
          aria-label="submit"
          className={styles.newsletterSubmit}
          style={{ position: 'absolute', bottom: 3, right: 3 }}
          disabled={isSending}
        />
      </form>
    </Card>
  )
}

Module.propTypes = {
  subheading: PropTypes.string.isRequired,
  heading: PropTypes.string,
}

Module.defaultProps = {
  heading: '',
}

import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import { mobile } from '../../styles/breakpoint'
import BackIcon from './img/back-icon.inline.svg'
import styles from './YearInReview.module.css'
import Switch from '../Switch'
import { ThemeContext } from '../ThemeSwitcher'
import { MonthContext } from './MonthContextProvider'

import { getMonthName } from './Month'

export default function Header({ months }) {
  const isMobile = useMediaQuery({ query: mobile })
  const { theme, setTheme } = useContext(ThemeContext)
  const [activeMonth] = useContext(MonthContext)

  const monthIndex = months.indexOf(activeMonth)

  const handleMonthClick = e => {
    e.preventDefault()

    const url = new URL(e.currentTarget.href)
    const section = document.querySelector(url.hash)

    if (section) {
      window.scrollTo({
        left: 0,
        top: section.getBoundingClientRect().top + window.scrollY - 226,
        behavior: 'smooth',
      })
    }
  }

  return (
    <header className={styles.header}>
      <Link className={styles.backLink} href="/">
        <BackIcon />
        Porto Rocha
      </Link>

      <Switch
        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        isOn={theme === 'dark'}
        className={styles.switch}
      />

      <nav
        className={styles.nav}
        style={{
          transform: `translateX(${monthIndex * (isMobile ? -39 : -99)}px)`,
        }}
      >
        {months.map(month => (
          <a
            onClick={handleMonthClick}
            className={[
              styles.monthLink,
              ...(month === activeMonth ? [styles.active] : []),
            ].join(' ')}
            href={`#${month}`}
          >
            <span className={styles.month}>{getMonthName(month)}</span>
          </a>
        ))}
      </nav>
    </header>
  )
}

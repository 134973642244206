import { useEffect, useCallback, useState } from 'react'

export default function useInView(refElement, offset = 0) {
  const [inView, setInView] = useState(false)

  const handleScroll = useCallback(() => {
    if (!refElement.current) {
      return
    }

    const rect = refElement.current.getBoundingClientRect()

    const top = rect.top + window.scrollY - window.innerHeight / 2 + offset
    const bottom =
      rect.bottom + window.scrollY - window.innerHeight / 2 + offset

    if (window.scrollY > top && window.scrollY < bottom) {
      setInView(true)
    } else {
      setInView(false)
    }
  })

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return inView
}

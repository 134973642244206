import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import styles from './YearInReview.module.css'
import Image from '../Image'

export default function ImageGrid({ heading, subheading, images }) {
  const imageGridClassName = [styles.imageGrid]

  if (
    heading.toLowerCase().includes('thank you') ||
    heading.toLowerCase().includes('some incredible clients')
  ) {
    imageGridClassName.push(styles.imageGridLogos)
  }

  return (
    <Card>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      {heading && (
        <h2
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: heading }}
          className={styles.heading}
        />
      )}

      <div className={imageGridClassName.join(' ')}>
        {images.map(image => (
          <div className={styles.imageContainer}>
            <Image className={styles.image} image={image.localFile} alt="" />
            {image.caption && (
              <p className={styles.imageGridCaption}>{image.caption}</p>
            )}
          </div>
        ))}
      </div>
    </Card>
  )
}

ImageGrid.propTypes = {
  heading: PropTypes.string,
}

ImageGrid.defaultProps = {
  heading: '',
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import { mobile } from '../../styles/breakpoint'
import styles from './ContentPanel.module.css'

export default function ContentPanel({
  children,
  width,
  offset,
  align,
  style,
  lineBreakAfter,
  offsetMobile,
  ...props
}) {
  const classes = [styles.columnPadding, styles.contentPanel]
  const layoutStyle = {}
  const [shouldUseMobileOffset, setShouldUseMobileOffset] = useState(false)

  const isMobile = useMediaQuery({ query: mobile })

  // width
  if (width === 'large') {
    classes.push(styles.large)
  } else if (width === 'medium') {
    classes.push(styles.medium)
  } else if (width === 'small') {
    classes.push(styles.small)
  }

  // line break
  if (lineBreakAfter) {
    classes.push(styles.lineBreakAfter)
  }

  // align
  if (align) {
    classes.push(styles[align])
  }

  // offset
  if (offset) {
    layoutStyle.marginTop = offset
  }

  if (offsetMobile && isMobile && shouldUseMobileOffset) {
    layoutStyle.marginTop = offsetMobile
  }

  useEffect(() => {
    if (isMobile) {
      setShouldUseMobileOffset(true) // workaround for SSR
    }
  }, [isMobile, setShouldUseMobileOffset])

  return (
    <div
      className={classes.join(' ')}
      style={{ ...layoutStyle, ...style }}
      {...props}
    >
      {children}
    </div>
  )
}

ContentPanel.propTypes = {
  width: PropTypes.number.isRequired,
  offset: PropTypes.string,
  offsetMobile: PropTypes.string,
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  lineBreakAfter: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

ContentPanel.defaultProps = {
  align: '',
  offset: null,
  offsetMobile: null,
  style: {},
  lineBreakAfter: false,
}

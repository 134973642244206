import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import styles from './YearInReview.module.css'
import StreetView from '../StreetView'

export default function Module({ subheading, heading, cities }) {
  const [activeCityIndex, setActiveCityIndex] = useState(0)

  const activeCity = cities[activeCityIndex]

  return (
    <Card>
      {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
      {heading && (
        <h2
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: heading }}
          className={styles.heading}
        />
      )}

      <div className={styles.locationButtons}>
        {cities.map((city, index) => (
          <button
            type="button"
            className={[
              styles.locationButton,
              ...(index === activeCityIndex
                ? [styles.activeLocationButton]
                : []),
            ].join(' ')}
            onClick={() => setActiveCityIndex(index)}
          >
            {city.initials}
          </button>
        ))}
      </div>

      <StreetView
        location={{ lat: activeCity.latitude, lng: activeCity.longitude }}
        heading={activeCity.heading}
        pitch={activeCity.pitch}
        // address1={activeCity.address1}
        // address2={activeCity.address2}
        responsiveHeight
      />
    </Card>
  )
}

Module.propTypes = {
  subheading: PropTypes.string.isRequired,
  heading: PropTypes.string,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      address1: PropTypes.string.isRequired,
      address2: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
      location: PropTypes.shape({
        lng: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
      }).isRequired,
      heading: PropTypes.number.isRequired,
      pitch: PropTypes.number.isRequired,
    })
  ).isRequired,
}

Module.defaultProps = {
  heading: '',
}

import React from 'react'
import PropTypes from 'prop-types'
import styles from '../YearInReview.module.css'

export default function PercentChange({ percentChange }) {
  const number = parseInt(percentChange, 10)
  const classes = [styles.percentChange]

  let percentChangeString
  if (number > 0) {
    percentChangeString = `↑${percentChange}%`
  } else if (number < 0) {
    percentChangeString = `↓${Math.abs(percentChange)}%`
    classes.push(styles.negativePercent)
  } else {
    percentChangeString = percentChange
  }

  return <p className={classes.join(' ')}>{percentChangeString}</p>
}

PercentChange.propTypes = {
  percentChange: PropTypes.string.isRequired,
}
